import { MessageSource } from 'constants/messagesource';
import { getEndOfToday, getOneMonthBeforeToday } from 'helpers/dateHelper';
import moment from 'moment';

export class DetailsFilterProperties {
    consumer?: string;
    producer?: string;
    messageSource?: string;

    fromDate: moment.Moment = getOneMonthBeforeToday();
    toDate: moment.Moment = getEndOfToday();

    selectedMessageSource: MessageSource = MessageSource.Archived;
    searchableKeyword = '';
    nrOfElements = 100;
}
