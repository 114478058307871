import { Box, CircularProgress } from '@mui/material';
import { FixedPageContent } from '@ost/mui';
import { fetchBaseData } from 'apirequestdefinitions/baseData';
import { useFetch } from 'hooks/useFetch';
import { IAppState } from 'models/interfaces/api/IAppState';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const Startup: React.FunctionComponent = ({ children }) => {
    const isStartingUp = useSelector(
        (state: IAppState) => state.api.resources.baseData.isPending
    );

    const { executeApiRequest: loadBaseData } = useFetch(fetchBaseData());

    useEffect(() => {
        void loadBaseData();
    }, [loadBaseData]);

    if (isStartingUp) {
        return (
            <FixedPageContent>
                <Box
                    sx={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress color='primary' />
                </Box>
            </FixedPageContent>
        );
    }

    return <>{children}</>;
};
