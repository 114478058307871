import { Button, Paper } from '@mui/material';
import { DataGridPremium, GridSortModel } from '@mui/x-data-grid-premium';
import { ActionBar, FixedPageContent, TitleBar } from '@ost/mui';
import { deleteMessage, sendMessage } from 'apirequestdefinitions/messages';
import { fetchSearch } from 'apirequestdefinitions/search';
import { MessageDetail } from 'components/pages/Details/MessageDetail';
import { Search } from 'components/pages/Details/Search';
import { SendMessage } from 'components/pages/SendMessage';
import { Content } from 'components/widgets/Content';
import { MessageSource } from 'constants/messagesource';
import { convertModel, convertModelArray } from 'helpers/model';
import { createErrorSnackbar } from 'helpers/snackbar';
import { useFetch } from 'hooks/useFetch';
import { useRoutingQueryStringFilter } from 'hooks/useRoutingQueryStringFilter';
import { OverviewEsbMessage } from 'models/OverviewEsbMessage';
import { OverviewStatistics } from 'models/OverviewStatistics';
import { IOverviewResult } from 'models/interfaces/IOverviewResult';
import { DetailsFilterProperties } from 'models/routing/DetailsFilterProperties';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';

export const Details = () => {
    const { queryFilter, setQueryFilter } = useRoutingQueryStringFilter(
        DetailsFilterProperties
    );
    const [messages, setMessages] = useState<OverviewEsbMessage[]>([]);
    const [stats, setStats] = useState<OverviewStatistics>();
    const [selectedMessage, setSelectedMessage] =
        useState<OverviewEsbMessage>();
    const [detailMessageId, setDetailMessageId] = useState<string | null>(null);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [sortModel, setSortModel] = useState<GridSortModel | undefined>([
        { field: 'creationDate', sort: 'desc' },
    ]);
    const [isMessageSendDialogOpen, setIsMessageSendDialogOpen] =
        useState(false);

    const onFetchSuccess = useCallback((result: IOverviewResult) => {
        setMessages(convertModelArray(OverviewEsbMessage, result.searchResult));
        setStats(convertModel(OverviewStatistics, result.stats));
    }, []);

    const onFetchFailed = useCallback(
        (error: unknown) =>
            createErrorSnackbar(error, 'Fehler beim Laden der Messages.'),
        []
    );

    const { executeApiRequest: loadData, isApiRequestPending } = useFetch(
        fetchSearch(queryFilter),
        onFetchSuccess,
        onFetchFailed
    );

    useEffect(() => {
        setSelectedIds([]);
        void loadData();
    }, [loadData]);

    const reset = () => {
        setQueryFilter(new DetailsFilterProperties());
    };

    useEffect(() => {
        const timer = setTimeout(
            () =>
                setSelectedMessage(
                    detailMessageId
                        ? getSelectedMessage(messages, detailMessageId)
                        : undefined
                ),
            50
        );
        return () => clearTimeout(timer);
    }, [detailMessageId, messages]);

    const onResendSuccess = useCallback(
        () =>
            enqueueSnackbar('Message erfolgreich versendet.', {
                variant: 'success',
            }),
        []
    );

    const onResendFailed = useCallback(
        () =>
            enqueueSnackbar('Message konnte nicht versendet werden.', {
                variant: 'error',
            }),
        []
    );

    const onResendFinished = useCallback(
        isSuccess => {
            if (isSuccess) {
                void loadData();
            }
        },
        [loadData]
    );

    const { executeApiRequest: resendMessage, isApiRequestPending: isSending } =
        useFetch(
            selectedIds.length === 1
                ? sendMessage(
                      queryFilter.selectedMessageSource,
                      getSelectedMessage(messages, selectedIds[0])
                  )
                : selectedIds
                      .map(id => getSelectedMessage(messages, id))
                      .sort((a, b) => {
                          if (a?.creationDate === undefined) {
                              return -1;
                          }
                          if (b?.creationDate === undefined) {
                              return 1;
                          }
                          return (
                              new Date(a.creationDate).getTime() -
                              new Date(b.creationDate).getTime()
                          );
                      })
                      .map(m =>
                          sendMessage(queryFilter.selectedMessageSource, m)
                      ),
            onResendSuccess,
            onResendFailed,
            onResendFinished
        );

    const onDeleteSuccess = useCallback(
        () =>
            enqueueSnackbar('Message erfolgreich gelöscht.', {
                variant: 'success',
            }),
        []
    );

    const onDeleteFailed = useCallback(
        () =>
            enqueueSnackbar('Message konnte nicht gelöscht werden.', {
                variant: 'error',
            }),
        []
    );

    const onDeleteFinished = useCallback(
        isSuccess => {
            if (isSuccess) {
                void loadData();
            }
        },
        [loadData]
    );

    const {
        executeApiRequest: deleteMessageReq,
        isApiRequestPending: isDeleting,
    } = useFetch(
        selectedIds.length === 1
            ? deleteMessage(
                  queryFilter.selectedMessageSource,
                  getSelectedMessage(messages, selectedIds[0])
              )
            : selectedIds.map(id =>
                  deleteMessage(
                      queryFilter.selectedMessageSource,
                      getSelectedMessage(messages, id)
                  )
              ),
        onDeleteSuccess,
        onDeleteFailed,
        onDeleteFinished
    );

    const isButtonDisabled =
        selectedIds.length === 0 || isSending || isDeleting;

    const handleMessageSendDialogClose = useCallback(
        () => setIsMessageSendDialogOpen(false),
        []
    );

    const handleMessageSendDialogOpen = () => setIsMessageSendDialogOpen(true);

    return (
        <FixedPageContent withActionbar>
            <TitleBar prefix='ESB Cockpit' title='Details' />
            <Search
                stats={stats ?? new OverviewStatistics()}
                filterProperties={queryFilter}
                setFilterProperties={setQueryFilter}
                onRefreshClick={() => void loadData()}
                onResetClick={reset}
                isRequestPending={isApiRequestPending}
            ></Search>
            <Content>
                <SendMessage
                    isDialogOpen={isMessageSendDialogOpen}
                    closeDialog={handleMessageSendDialogClose}
                />
                <Paper
                    elevation={2}
                    sx={{
                        width: { xs: '100%', lg: '75%' },
                        height: { xs: '50%', lg: '100%' },
                    }}
                >
                    <DataGridPremium
                        rows={messages}
                        loading={isApiRequestPending}
                        getRowId={m => m.messageId}
                        columns={[
                            {
                                headerClassName: 'header',
                                field: 'messageType',
                                headerName: 'Typ',
                                flex: 1,
                            },
                            {
                                headerClassName: 'header',
                                field: 'creationDate',
                                headerName: 'Created At',
                                groupable: false,
                                flex: 2,
                                valueGetter: params =>
                                    new Date(params.row.creationDate),
                                type: 'dateTime',
                            },
                            {
                                headerClassName: 'header',
                                field: 'processedDate',
                                headerName: 'Processed At',
                                groupable: false,
                                flex: 1,
                                valueGetter: params =>
                                    new Date(params.row.processedDate),
                                type: 'dateTime',
                            },
                            {
                                headerClassName: 'header',
                                field: 'producerName',
                                headerName: 'Producer',
                                flex: 1,
                            },
                            {
                                headerClassName: 'header',
                                field: 'consumerName',
                                headerName: 'Consumer',
                                flex: 1,
                            },
                        ]}
                        density='compact'
                        disableColumnReorder
                        disableColumnResize
                        disableAggregation
                        sortModel={sortModel}
                        onSortModelChange={model => setSortModel(model)}
                        onRowSelectionModelChange={rowSelectionModel => {
                            setSelectedIds(rowSelectionModel as string[]);
                            setDetailMessageId(
                                rowSelectionModel[
                                    rowSelectionModel.length - 1
                                ] as string
                            );
                        }}
                        sx={{
                            border: 'none',
                            '& .header': {
                                borderBottom: '2px groove',
                            },
                            mx: 1,
                        }}
                    />
                </Paper>
                <Paper
                    elevation={2}
                    sx={{
                        width: { xs: '100%', lg: '25%' },
                        height: { xs: '50%', lg: '100%' },
                        minWidth: '25%',
                        overflow: 'auto',
                        m: { xs: '25px 0 0 0', lg: '0 0 0 25px' },
                    }}
                >
                    <MessageDetail message={selectedMessage} />
                </Paper>
            </Content>
            <ActionBar>
                <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    sx={{ mr: 2 }}
                    disabled={
                        isButtonDisabled ||
                        queryFilter.selectedMessageSource ===
                            MessageSource.Blacklisted
                    }
                    onClick={() => void resendMessage()}
                >
                    {selectedIds.length > 1
                        ? `${selectedIds.length} Messages erneut versenden`
                        : 'Message erneut versenden'}
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    sx={{ mr: 2 }}
                    onClick={() => handleMessageSendDialogOpen()}
                >
                    Neue Message versenden
                </Button>
                <Button
                    variant='contained'
                    color='error'
                    size='small'
                    disabled={
                        isButtonDisabled ||
                        queryFilter.selectedMessageSource ===
                            MessageSource.Archived
                    }
                    onClick={() => void deleteMessageReq()}
                >
                    {selectedIds.length > 1
                        ? `${selectedIds.length} Messages löschen`
                        : 'Message löschen'}
                </Button>
            </ActionBar>
        </FixedPageContent>
    );
};

const getSelectedMessage = (
    messages: OverviewEsbMessage[],
    selectedId: string
) => messages.find(item => item.messageId === selectedId);
