import RefreshIcon from '@mui/icons-material/Refresh';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Box, Button, Paper, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MessageContainerButton } from 'components/widgets/MessageContainerButton';
import { Select } from 'components/widgets/Select';
import { MessageSource } from 'constants/messagesource';
import { getEndOfToday, getOneMonthBeforeToday } from 'helpers/dateHelper';
import { cloneDeep } from 'lodash';
import { OverviewStatistics } from 'models/OverviewStatistics';
import { IBaseData } from 'models/interfaces/IBaseData';
import { IAppState } from 'models/interfaces/api/IAppState';
import { DetailsFilterProperties } from 'models/routing/DetailsFilterProperties';
import { Moment } from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export interface ISearchProperties {
    stats: OverviewStatistics;
    filterProperties: DetailsFilterProperties;
    setFilterProperties: (props: DetailsFilterProperties) => void;
    onRefreshClick: () => void;
    onResetClick: () => void;
    isRequestPending: boolean;
}

export const Search = (props: ISearchProperties) => {
    const {
        stats,
        filterProperties,
        setFilterProperties,
        onRefreshClick,
        onResetClick,
        isRequestPending,
    } = props;
    const baseData = useSelector<IAppState, IBaseData | undefined>(
        state => state.api.resources.baseData.data
    );

    const [fromDate, setFromDate] = useState(filterProperties.fromDate);
    const [toDate, setToDate] = useState(filterProperties.toDate);
    const [searchableKeyword, setSearchableKeyword] = useState('');
    const [nrOfElements, setNrOfElements] = useState(
        `${filterProperties.nrOfElements}`
    );

    const handleValueChange = useCallback(
        (
            key: keyof DetailsFilterProperties,
            value: string | MessageSource | Date | number | undefined
        ) => {
            if (filterProperties[key] !== value) {
                const newFilter = cloneDeep(filterProperties);
                Object.assign(newFilter, {
                    [key]: value,
                });
                setFilterProperties(newFilter);
            }
        },
        [filterProperties, setFilterProperties]
    );

    useEffect(() => {
        const timer = setTimeout(
            () => handleValueChange('searchableKeyword', searchableKeyword),
            500
        );
        return () => clearTimeout(timer);
    }, [handleValueChange, searchableKeyword]);

    useEffect(() => {
        const timer = setTimeout(
            () => handleValueChange('nrOfElements', Number(nrOfElements)),
            500
        );
        return () => clearTimeout(timer);
    }, [handleValueChange, nrOfElements]);

    const handleDateChange = (
        key: keyof DetailsFilterProperties,
        value: Moment | null
    ) => {
        if (value?.isValid()) {
            const newFilter = cloneDeep(filterProperties);
            Object.assign(newFilter, {
                [key]: value,
            });
            setFilterProperties(newFilter);
            if (key === 'fromDate') {
                setFromDate(value);
            }
            if (key === 'toDate') {
                setToDate(value);
            }
        }
    };

    const reset = () => {
        setSearchableKeyword('');
        setNrOfElements('100');
        setFromDate(getOneMonthBeforeToday());
        setToDate(getEndOfToday());
        onResetClick();
    };

    return (
        <Paper
            elevation={2}
            sx={{ mt: '20px', mx: '20px', py: '10px', px: '20px' }}
        >
            <Box
                sx={{
                    display: {
                        xs: 'grid',
                        lg: 'flex',
                    },
                    gridTemplateColumns: '1fr',
                    rowGap: '15px',
                    justifyItems: 'center',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ display: 'flex' }}>
                    <MessageContainerButton
                        onClick={() =>
                            handleValueChange(
                                'selectedMessageSource',
                                MessageSource.Archived
                            )
                        }
                        isRequestPending={isRequestPending}
                        numberOfItems={stats.archivedItems}
                        text='Archiv'
                        isSelected={
                            filterProperties.selectedMessageSource ===
                            MessageSource.Archived
                        }
                        color='success'
                    />
                    <MessageContainerButton
                        onClick={() =>
                            handleValueChange(
                                'selectedMessageSource',
                                MessageSource.DeadLettered
                            )
                        }
                        isRequestPending={isRequestPending}
                        numberOfItems={stats.errorItems}
                        text='Fehler'
                        isSelected={
                            filterProperties.selectedMessageSource ===
                            MessageSource.DeadLettered
                        }
                        color='warning'
                    />
                    <MessageContainerButton
                        onClick={() =>
                            handleValueChange(
                                'selectedMessageSource',
                                MessageSource.Blacklisted
                            )
                        }
                        isRequestPending={isRequestPending}
                        numberOfItems={stats.blacklistedItems}
                        text='Blacklist'
                        isSelected={
                            filterProperties.selectedMessageSource ===
                            MessageSource.Blacklisted
                        }
                        color='error'
                    />
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        ml: { md: '0px', lg: '30px' },
                        gridTemplateColumns: {
                            xs: 'minmax(0, 1fr)',
                            sm: 'repeat(2, minmax(0, 1fr))',
                            md: 'repeat(2, minmax(0, 1fr))',
                            lg: 'repeat(2, minmax(0, 1fr))',
                            xl: 'repeat(4, minmax(0, 1fr))',
                        },
                        columnGap: '25px',
                        mb: { xs: '15px', sm: 0 },
                    }}
                >
                    <TextField
                        label='Searchable Keyword'
                        name='searchableKeyword'
                        value={searchableKeyword}
                        onChange={e => setSearchableKeyword(e.target.value)}
                        disabled={isRequestPending}
                        size='small'
                        sx={{ my: '10px' }}
                        variant='outlined'
                    />
                    <Select
                        label='Message Typ'
                        value={filterProperties.messageSource}
                        data={baseData?.messageTypes ?? []}
                        onItemSelect={item => {
                            handleValueChange('messageSource', item);
                        }}
                        disabled={isRequestPending}
                    />
                    <Select
                        label='Producer'
                        value={filterProperties.producer}
                        data={baseData?.producers ?? []}
                        onItemSelect={item => {
                            handleValueChange('producer', item);
                        }}
                        disabled={isRequestPending}
                    />
                    <Select
                        label='Consumer'
                        value={filterProperties.consumer}
                        data={baseData?.consumers ?? []}
                        onItemSelect={item => {
                            handleValueChange('consumer', item);
                        }}
                        disabled={isRequestPending}
                    />
                    <DatePicker
                        label='Von'
                        format='DD.MM.YYYY'
                        value={fromDate}
                        onChange={value => handleDateChange('fromDate', value)}
                        disabled={isRequestPending}
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ my: '10px' }}
                    />
                    <DatePicker
                        label='Bis'
                        format='DD.MM.YYYY'
                        value={toDate}
                        onChange={value => handleDateChange('toDate', value)}
                        disabled={isRequestPending}
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ my: '10px' }}
                    />
                    <TextField
                        type='number'
                        label='Anzahl Elemente'
                        name='nrOfElements'
                        value={nrOfElements}
                        onChange={e => setNrOfElements(e.target.value)}
                        disabled={isRequestPending}
                        size='small'
                        sx={{ my: '10px' }}
                        variant='outlined'
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Button
                            variant='contained'
                            onClick={reset}
                            disabled={isRequestPending}
                            startIcon={<RestartAltIcon />}
                            color='error'
                            sx={{ textTransform: 'none', mr: 1 }}
                        >
                            Zurücksetzen
                        </Button>
                        <Button
                            variant='contained'
                            onClick={onRefreshClick}
                            disabled={isRequestPending}
                            startIcon={<RefreshIcon />}
                            color='success'
                            sx={{ textTransform: 'none' }}
                        >
                            Aktualisieren
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Paper>
    );
};
