import {
    AuthenticationResult,
    EventMessage,
    EventType,
    PublicClientApplication,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { LicenseInfo } from '@mui/x-data-grid-premium';
import { msalConfig } from 'authConfig';
import { App } from 'components/App';
import { MUI_LICENSE } from 'constants/env';
import { enqueueSnackbar } from 'notistack';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from 'reportWebVitals';

const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }

    if (event.eventType === EventType.LOGIN_FAILURE) {
        enqueueSnackbar(`Error during login: ${JSON.stringify(event)}`, {
            variant: 'error',
        });
    }
});

LicenseInfo.setLicenseKey(MUI_LICENSE);

ReactDOM.render(
    <StrictMode>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    </StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
void reportWebVitals();
