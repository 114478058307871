import { IApiRequestDefinition } from 'models/interfaces/api/IApiRequestDefinition';

export const fetchContractVersions = (): IApiRequestDefinition => ({
    method: 'get',
    url: `/esb/api/app/contracts`,
    canExecute: true
});

export const fetchContractVersion = (
    contractType: string,
    version: string
): IApiRequestDefinition => ({
    method: 'get',
    url: `/esb/api/app/contract/${contractType}/${version}`,
    canExecute: contractType.length > 0 && version.length > 0
});
