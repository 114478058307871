import { cloneDeep } from 'lodash';
import { IConvertable } from 'models/interfaces/IConvertable';

export class OverviewEsbMessage implements IConvertable<OverviewEsbMessage> {
    producerName = '';
    consumerName = '';
    creationDate = '';
    processedDate = '';
    deadletterReason = '';
    messageType = '';
    id = '';
    messageId = '';

    convertToModel = () => {
        const clone = cloneDeep(this);
        clone.messageId = `${clone.id}-${clone.messageType}`;
        return clone;
    };
}
