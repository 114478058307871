import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, CircularProgress, Theme, useMediaQuery } from '@mui/material';
import { fetchMessageDetail } from 'apirequestdefinitions/messages';
import { EsbMessageRenderer } from 'components/widgets/EsbMessageRenderer';
import { NonIdealState } from 'components/widgets/NonIdealState';
import { DEFAULT_TIMEOUT } from 'constants/timer';
import { useFetch } from 'hooks/useFetch';
import { OverviewEsbMessage } from 'models/OverviewEsbMessage';
import { useCallback, useEffect, useState } from 'react';
import { DetailEsbMessage } from 'models/DetailEsbMessage';

interface IMessageDetailProps {
    message?: OverviewEsbMessage;
}

export const MessageDetail = (props: IMessageDetailProps) => {
    const { message } = props;
    const [messageDetail, setMessageDetail] = useState<
        DetailEsbMessage | string
    >();
    const isBelowLg = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('lg')
    );

    let partitionKey = '';
    let rowKey = '';
    if (message) {
        partitionKey = message.messageType;
        rowKey = message.id;
    }

    const onFetchSuccess = useCallback(
        (result: DetailEsbMessage) => setMessageDetail(result),
        []
    );
    const onFetchFailed = useCallback(
        () => setMessageDetail('Kann Details nicht anzeigen'),
        []
    );

    const { executeApiRequest: loadDetails, isApiRequestPending } = useFetch(
        fetchMessageDetail(partitionKey, rowKey),
        onFetchSuccess,
        onFetchFailed
    );

    useEffect(() => {
        const timer = setTimeout(() => {
            if (message) {
                void loadDetails();
            } else {
                setMessageDetail(undefined);
            }
        }, DEFAULT_TIMEOUT);
        return () => clearTimeout(timer);
    }, [loadDetails, message]);

    if (isApiRequestPending) {
        return (
            <Box
                sx={{
                    overflow: 'auto',
                    p: '0.5em 1em',
                    display: 'grid',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <CircularProgress color='primary' />
            </Box>
        );
    }

    if (messageDetail && typeof messageDetail !== 'object') {
        return (
            <Box sx={{ overflow: 'auto', p: '0.5em 1em' }}>
                <NonIdealState
                    title='Details können nicht angezeigt werden'
                    icon={<ErrorIcon />}
                />
            </Box>
        );
    }

    return (
        <Box sx={{ overflow: 'auto', p: '0.5em 1em' }}>
            {messageDetail ? (
                <EsbMessageRenderer
                    message={messageDetail as DetailEsbMessage}
                />
            ) : (
                <NonIdealState
                    title='Message auswählen'
                    icon={isBelowLg ? <ArrowUpward /> : <ArrowBackIcon />}
                />
            )}
        </Box>
    );
};
