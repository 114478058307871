import { Box, Button, Typography } from '@mui/material';
import { formatHugeNumber } from 'helpers/numberHelper';

interface IProps {
    onClick: () => void;
    isRequestPending: boolean;
    numberOfItems: string;
    text: string;
    isSelected: boolean;
    color:
        | 'inherit'
        | 'primary'
        | 'secondary'
        | 'success'
        | 'error'
        | 'info'
        | 'warning';
}

export const MessageContainerButton = ({
    onClick,
    isRequestPending,
    numberOfItems,
    text,
    isSelected,
    color,
}: IProps) => {
    return (
        <Button
            variant='contained'
            onClick={onClick}
            disabled={isRequestPending}
            color={isSelected ? color : `inherit`}
            sx={{
                height: { xs: 110, sm: 90 },
                width: { xs: 100, sm: 150 },
                margin: '10px',
            }}
        >
            <Box>
                <Typography variant='h4'>
                    {formatHugeNumber(numberOfItems)}
                </Typography>
                <Typography variant='body1'>{text}</Typography>
            </Box>
        </Button>
    );
};
