import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, CircularProgress, Theme, useMediaQuery } from '@mui/material';
import { fetchContractVersion } from 'apirequestdefinitions/documentation';
import { JsonObjectRenderer } from 'components/widgets/JsonObjectRenderer';
import { NonIdealState } from 'components/widgets/NonIdealState';
import { DEFAULT_TIMEOUT } from 'constants/timer';
import { useFetch } from 'hooks/useFetch';
import { useCallback, useEffect, useState } from 'react';

interface IContractDetailProps {
    contractType?: string;
    version?: string;
}

export const ContractDetail = (props: IContractDetailProps) => {
    const { contractType, version } = props;
    const [contractVersion, setContractVersion] = useState<string>();
    const isBelowLg = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('lg')
    );

    const onLoadSuccess = useCallback(
        (result: string) => setContractVersion(result),
        []
    );
    const onLoadFailed = useCallback(
        () => setContractVersion('Kann Details nicht anzeigen'),
        []
    );

    const { executeApiRequest: loadDetails, isApiRequestPending } = useFetch(
        fetchContractVersion(contractType ?? '', version ?? ''),
        onLoadSuccess,
        onLoadFailed
    );

    useEffect(() => {
        const timer = setTimeout(() => {
            if (contractType && version) {
                void loadDetails();
            } else {
                setContractVersion(undefined);
            }
        }, DEFAULT_TIMEOUT);
        return () => clearTimeout(timer);
    }, [contractType, loadDetails, version]);

    if (isApiRequestPending) {
        return (
            <Box
                sx={{
                    overflow: 'auto',
                    p: '0.5em 1em',
                    display: 'grid',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <CircularProgress color='primary' />
            </Box>
        );
    }

    if (contractVersion && typeof contractVersion !== 'object') {
        return (
            <Box sx={{ overflow: 'auto', p: '0.5em 1em' }}>
                <NonIdealState
                    title='Details können nicht angezeigt werden'
                    icon={<ErrorIcon />}
                />
            </Box>
        );
    }

    return (
        <Box sx={{ overflow: 'auto', p: '0.5em 1em' }}>
            {contractVersion ? (
                <JsonObjectRenderer json={contractVersion} />
            ) : (
                <NonIdealState
                    title='Contract auswählen'
                    icon={isBelowLg ? <ArrowUpward /> : <ArrowBackIcon />}
                />
            )}
        </Box>
    );
};
