import { useMsal } from '@azure/msal-react';
import { hasUserNeededRoles } from 'helpers/authRoleChecker';
import React, { useEffect, useState } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

// adapted from https://github.com/Azure-Samples/ms-identity-javascript-react-tutorial/tree/main/5-AccessControl/1-call-api-roles

interface IRouteGuardProps {
    component:
        | React.ComponentType<RouteComponentProps<object>>
        | React.ComponentType<object>;
    path: string;
    roles: string[];
    exact?: boolean;
}

export const RouteGuard = ({
    component,
    path,
    roles,
    exact,
}: IRouteGuardProps) => {
    const { instance } = useMsal();
    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
        setIsAuthorized(hasUserNeededRoles(roles, instance));
    }, [instance, roles]);

    return (
        <>
            {isAuthorized ? (
                <Route component={component} path={path} exact={exact} />
            ) : (
                <div>
                    <h3>You are unauthorized to view this content.</h3>
                </div>
            )}
        </>
    );
};
