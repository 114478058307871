import { Paper } from '@mui/material';
import {
    DataGridPremium,
    GRID_TREE_DATA_GROUPING_FIELD,
    GridRowSelectionModel,
    GridSortModel,
} from '@mui/x-data-grid-premium';
import { FixedPageContent, TitleBar } from '@ost/mui';
import { fetchContractVersions } from 'apirequestdefinitions/documentation';
import { ContractDetail } from 'components/pages/Documentation/ContractDetail';
import { Content } from 'components/widgets/Content';
import { getDateDisplay } from 'helpers/model';
import { createErrorSnackbar } from 'helpers/snackbar';
import { useFetch } from 'hooks/useFetch';
import { useRoutingQueryStringFilter } from 'hooks/useRoutingQueryStringFilter';
import { DocumentationOverviewItem } from 'models/Documentation/DocumentationOverviewItem';
import { IOverviewVersion } from 'models/Documentation/IOverviewVersion';
import { DocumentationQueryParams } from 'models/routing/DocumentationQueryParams';
import { useCallback, useEffect, useState } from 'react';

export const Documentation = () => {
    const [contractVersions, setContractVersions] = useState<
        DocumentationOverviewItem[]
    >([]);
    const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>();
    const [selectedDocItem, setSelectedDocItem] =
        useState<DocumentationOverviewItem>();
    const [sortModel, setSortModel] = useState<GridSortModel | undefined>([
        { field: GRID_TREE_DATA_GROUPING_FIELD, sort: 'desc' },
    ]);

    const { queryFilter, setQueryFilter } = useRoutingQueryStringFilter(
        DocumentationQueryParams
    );

    const onLoadSuccess = useCallback((result: IOverviewVersion[]) => {
        setContractVersions(createHierarchicalData(result));
    }, []);

    const onLoadFailed = useCallback(
        (error: unknown) =>
            createErrorSnackbar(
                error,
                'Fehler beim Laden der Contract-Versionen.'
            ),
        []
    );

    const { executeApiRequest: loadData, isApiRequestPending } = useFetch(
        fetchContractVersions(),
        onLoadSuccess,
        onLoadFailed
    );

    useEffect(() => {
        void loadData();
    }, [loadData]);

    useEffect(() => {
        if (contractVersions.length > 0) {
            if (selectedIds && selectedIds.length === 1) {
                const item = getSelectedDocItem(
                    selectedIds as string[],
                    contractVersions
                );
                if (item) {
                    setSelectedDocItem(item);
                    setQueryFilter({
                        type: item.contractType,
                        version: item.version,
                    });
                    return;
                }
            }
            setQueryFilter(new DocumentationQueryParams());
        }
        setSelectedDocItem(undefined);
    }, [contractVersions, selectedIds, setQueryFilter]);

    useEffect(() => {
        if (queryFilter.version && queryFilter.type) {
            setSelectedIds([`${queryFilter.version}-${queryFilter.type}`]);
        }
    }, [queryFilter.version, queryFilter.type]);

    return (
        <FixedPageContent>
            <TitleBar prefix='ESB Cockpit' title='Dokumentation' />
            <Content>
                <Paper
                    elevation={2}
                    sx={{
                        width: { xs: '100%', lg: '50%' },
                        height: { xs: '50%', lg: '100%' },
                    }}
                >
                    <DataGridPremium
                        rows={contractVersions}
                        loading={isApiRequestPending}
                        columns={[
                            {
                                headerClassName: 'header',
                                field: 'creationDateDisplay',
                                headerName: 'Datum',
                                flex: 1,
                                groupable: false,
                            },
                        ]}
                        treeData
                        getTreeDataPath={item => item.path}
                        groupingColDef={{
                            headerClassName: 'header',
                            headerName: 'Version / Typ',
                            sortable: true,
                        }}
                        sortModel={sortModel}
                        onSortModelChange={model => setSortModel(model)}
                        density='compact'
                        disableColumnReorder
                        disableColumnResize
                        disableAggregation
                        disableMultipleRowSelection
                        isRowSelectable={params =>
                            params.row.contractType !== ''
                        }
                        isGroupExpandedByDefault={node => {
                            return node.id === queryFilter.version;
                        }}
                        rowSelectionModel={selectedIds}
                        onRowSelectionModelChange={rowSelectionModel => {
                            setSelectedIds(rowSelectionModel);
                        }}
                        sx={{
                            border: 'none',
                            '& .header': {
                                borderBottom: '2px groove',
                            },
                            mx: 1,
                        }}
                    />
                </Paper>
                <Paper
                    elevation={2}
                    sx={{
                        width: { xs: '100%', lg: '50%' },
                        height: { xs: '50%', lg: '100%' },
                        overflow: 'auto',
                        m: { xs: '25px 0 0 0', lg: '0 0 0 25px' },
                    }}
                >
                    <ContractDetail
                        version={selectedDocItem?.version}
                        contractType={selectedDocItem?.contractType}
                    />
                </Paper>
            </Content>
        </FixedPageContent>
    );
};

const createHierarchicalData = (versions: IOverviewVersion[]) => {
    const result: DocumentationOverviewItem[] = [];
    versions.forEach(v => {
        result.push({
            id: v.number,
            version: v.number,
            contractType: '',
            creationDate: v.creationDate,
            creationDateDisplay: getDateDisplay(v.creationDate),
            path: [v.number],
        });

        v.contracts.forEach(c => {
            result.push({
                id: `${v.number}-${c.name}`,
                version: v.number,
                contractType: c.name,
                creationDate: '',
                creationDateDisplay: '',
                path: [v.number, c.name],
            });
        });
    });
    return result;
};

const getSelectedDocItem = (
    markedIds: string[],
    contractVersions: DocumentationOverviewItem[]
): DocumentationOverviewItem | undefined =>
    contractVersions.find(c => c.id === markedIds[0]);
