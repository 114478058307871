import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'authConfig';
import { API_BASEURL } from 'constants/env';
import { saveApiResult } from 'helpers/api';
import { callCallback } from 'helpers/callback';
import { useStoredObject } from 'hooks/useStoredObject';
import { IBaseData } from 'models/interfaces/IBaseData';
import { IApiRequestDefinition } from 'models/interfaces/api/IApiRequestDefinition';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

export const useFetch = <R>(
    definition: IApiRequestDefinition | IApiRequestDefinition[],
    onSuccess?: (result: R) => void,
    onFailure?: (result?: R) => void,
    onFinished?: (success?: boolean) => void
) => {
    const storedDefinition = useStoredObject(definition);
    const { instance, accounts } = useMsal();
    const dispatch = useDispatch();
    const [isApiRequestPending, setIsApiRequestPending] = useState(false);

    const fetchData = useCallback(
        async (
            accessToken: string,
            definition: IApiRequestDefinition,
            successStates: boolean[]
        ) => {
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('pragma', 'no-cache');
            headers.append('cache-control', 'no-cache');
            headers.append('Authorization', `Bearer ${accessToken}`);

            const init: RequestInit = {
                method: definition.method,
                body:
                    typeof definition.body === 'object'
                        ? JSON.stringify(definition.body)
                        : definition.body,
                headers: headers,
                mode: 'cors',
            };
            try {
                const response = await fetch(
                    `${API_BASEURL}${definition.url}`,
                    init
                );
                if (response.ok) {
                    successStates.push(true);
                    if (response.status === 204) {
                        callCallback(onSuccess, null as R);
                    } else {
                        const result = (await response.json()) as R;
                        if (definition.resource) {
                            dispatch(
                                saveApiResult(
                                    definition.resource,
                                    result as IBaseData,
                                    definition.appendData
                                )
                            );
                        }
                        callCallback(onSuccess, result);
                    }
                } else {
                    successStates.push(false);
                    await handleError(response, onFailure);
                }
            } catch (ex) {
                successStates.push(false);
                /* timeout/offline */
                if (ex instanceof Error && ex.message.includes('Failed to')) {
                    // user might not be authenticated anymore -> refresh page
                    window.location.reload();
                }
            } finally {
                setIsApiRequestPending(false);
            }
        },
        [onFailure, onSuccess, dispatch]
    );

    const fetchDataWithAccount = useCallback(
        async (
            request: {
                account: AccountInfo;
                scopes: string[];
            },
            definition: IApiRequestDefinition,
            successStates: boolean[]
        ) => {
            try {
                const response = await instance.acquireTokenSilent(request);
                await fetchData(
                    response.accessToken,
                    definition,
                    successStates
                );
            } catch {
                try {
                    const response = await instance.acquireTokenPopup(request);
                    await fetchData(
                        response.accessToken,
                        definition,
                        successStates
                    );
                } catch {
                    setIsApiRequestPending(false);
                }
            }
        },
        [fetchData, instance]
    );

    const executeApiRequest = useCallback(async () => {
        const request = {
            ...loginRequest,
            account: accounts[0],
        };
        const successStates: boolean[] = [];
        setIsApiRequestPending(true);
        // Silently acquires an access token which is then attached to a request
        if (
            Array.isArray(storedDefinition) &&
            storedDefinition.filter(d => d.canExecute).length > 0
        ) {
            await Promise.all(
                storedDefinition
                    .filter(d => d.canExecute)
                    .map(d => fetchDataWithAccount(request, d, successStates))
            );
        } else if ((storedDefinition as IApiRequestDefinition).canExecute) {
            await fetchDataWithAccount(
                request,
                storedDefinition as IApiRequestDefinition,
                successStates
            );
        } else {
            setIsApiRequestPending(false);
        }
        callCallback(
            onFinished,
            successStates.every(s => s)
        );
    }, [accounts, storedDefinition, onFinished, fetchDataWithAccount]);
    return { executeApiRequest, isApiRequestPending };
};

const handleError = async <R>(
    response: Response,
    onFailure?: (result?: R) => void
) => {
    if (response.status === 302) {
        window.location.reload();
    } else if (response.status === 400) {
        let shouldShowToast = true;
        const result = (await response.json()) as R;
        if (onFailure) {
            shouldShowToast = false;
            callCallback(onFailure, result);
        }
        if (shouldShowToast) {
            enqueueSnackbar(
                'Die Aktion ist aufgrund von ungültigen Daten fehlgeschlagen.',
                { variant: 'error' }
            );
        }
        return;
    } else if (response.status === 401 || response.status === 403) {
        enqueueSnackbar(
            'Die Aktion ist aufgrund von fehlenden oder abgelaufenen Berechtigungen fehlgeschlagen.',
            { variant: 'error' }
        );
    } else if (response.status === 404) {
        enqueueSnackbar(
            'Die angefragte Ressource konnte nicht gefunden werden.',
            { variant: 'error' }
        );
    } else if (response.status === 409) {
        // do nothing -> caller needs to show a toast
    } else if (!onFailure) {
        enqueueSnackbar(
            `Unbekannter Fehler aufgetreten (${response.status}). Laden Sie die Seite neu und versuchen Sie es nochmals!`,
            { variant: 'error' }
        );
    }

    callCallback(onFailure, response.status as R);
};
