import { Button, Paper } from '@mui/material';
import { DataGridPremium, GridSortModel } from '@mui/x-data-grid-premium';
import { ActionBar, FixedPageContent, TitleBar } from '@ost/mui';
import { fetchEsbComponents } from 'apirequestdefinitions/registration';
import { AddComponentDialog } from 'components/pages/Registration/AddComponentDialog';
import { DeleteComponentDialog } from 'components/pages/Registration/DeleteComponentDialog';
import { Content } from 'components/widgets/Content';
import { convertModelArray } from 'helpers/model';
import { createErrorSnackbar } from 'helpers/snackbar';
import { useFetch } from 'hooks/useFetch';
import { ComponentRegistration } from 'models/ComponentRegistration';
import { useCallback, useEffect, useState } from 'react';

export const Registration = () => {
    const [isAddComponentDialogOpen, setIsAddComponentDialogOpen] =
        useState(false);
    const [isDeleteComponentDialogOpen, setIsDeleteComponentDialogOpen] =
        useState(false);

    const [components, setComponents] = useState<ComponentRegistration[]>([]);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [sortModel, setSortModel] = useState<GridSortModel | undefined>([
        { field: 'name', sort: 'asc' },
    ]);

    const onFetchSuccess = useCallback((result: ComponentRegistration[]) => {
        setComponents(convertModelArray(ComponentRegistration, result));
    }, []);

    const onFetchFailed = useCallback(
        (error: unknown) =>
            createErrorSnackbar(error, 'Fehler beim Laden der Komponenten.'),
        []
    );
    const { executeApiRequest: loadData, isApiRequestPending } = useFetch(
        fetchEsbComponents(),
        onFetchSuccess,
        onFetchFailed
    );

    useEffect(() => {
        void loadData();
    }, [loadData]);

    const onCloseAddComponentDialog = useCallback(() => {
        setIsAddComponentDialogOpen(false);
        void loadData();
    }, [loadData]);

    const onCloseDeleteComponentDialog = useCallback(() => {
        setIsDeleteComponentDialogOpen(false);
        void loadData();
        setSelectedIds([]);
    }, [loadData]);

    return (
        <>
            <AddComponentDialog
                isOpen={isAddComponentDialogOpen}
                onCloseDialog={onCloseAddComponentDialog}
            />
            <DeleteComponentDialog
                isOpen={isDeleteComponentDialogOpen}
                onCloseDialog={onCloseDeleteComponentDialog}
                component={
                    selectedIds.length === 1
                        ? components.find(c => c.id === selectedIds[0])
                        : undefined
                }
            />
            <FixedPageContent withActionbar>
                <TitleBar prefix='ESB Cockpit' title='ESB Komponenten' />
                <Content>
                    <Paper elevation={2} sx={{ width: '100%' }}>
                        <DataGridPremium
                            rows={components}
                            loading={isApiRequestPending}
                            columns={[
                                {
                                    headerClassName: 'header',
                                    field: 'name',
                                    headerName: 'Name',
                                    flex: 1,
                                },
                                {
                                    headerClassName: 'header',
                                    field: 'typeDisplay',
                                    headerName: 'Typ',
                                    flex: 1,
                                },
                                {
                                    headerClassName: 'header',
                                    field: 'consumedMessageTypesDisplay',
                                    headerName: 'Consumed Message Types',
                                    flex: 1,
                                },
                                {
                                    headerClassName: 'header',
                                    field: 'producedMessageTypesDisplay',
                                    headerName: 'Produced Message Types',
                                    flex: 1,
                                },
                                {
                                    headerClassName: 'header',
                                    field: 'heartbeatInterval',
                                    headerName: 'Heartbeat interval',
                                    flex: 1,
                                },
                                {
                                    headerClassName: 'header',
                                    field: 'lastHeartbeat',
                                    headerName: 'Last heartbeat',
                                    flex: 1,
                                },
                                {
                                    headerClassName: 'header',
                                    field: 'id',
                                    headerName: 'Id',
                                    flex: 2,
                                },
                            ]}
                            density='compact'
                            disableColumnReorder
                            disableColumnResize
                            disableAggregation
                            disableMultipleRowSelection
                            sortModel={sortModel}
                            onSortModelChange={model => setSortModel(model)}
                            onRowSelectionModelChange={rowSelectionModel => {
                                setSelectedIds(rowSelectionModel as string[]);
                            }}
                            sx={{
                                border: 'none',
                                '& .header': {
                                    borderBottom: '2px groove',
                                },
                                mx: 1,
                            }}
                        />
                    </Paper>
                </Content>
                <ActionBar>
                    <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        sx={{ mr: 2 }}
                        onClick={() => setIsAddComponentDialogOpen(true)}
                    >
                        Komponente erstellen
                    </Button>
                    <Button
                        variant='contained'
                        color='error'
                        size='small'
                        onClick={() => setIsDeleteComponentDialogOpen(true)}
                        disabled={
                            selectedIds.length !== 1 || isApiRequestPending
                        }
                    >
                        Komponente entfernen
                    </Button>
                </ActionBar>
            </FixedPageContent>
        </>
    );
};
