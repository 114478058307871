import { IBaseData } from 'models/interfaces/IBaseData';
import {
    API_RESULT,
    IApiResultAction,
} from 'models/interfaces/api/IApiResultAction';
import { IApiState } from 'models/interfaces/api/IApiState';

export const apiInitState: IApiState = {
    resources: {
        baseData: {
            data: {
                consumers: [],
                producers: [],
                messageTypes: [],
            },
            isPending: true,
        },
    },
};

export const apiReducer = (
    state: IApiState = apiInitState,
    action: IApiResultAction
): IApiState => {
    switch (action.type) {
        case API_RESULT:
            return {
                ...state,
                resources: {
                    ...state.resources,
                    [action.resource]: {
                        data: buildData(
                            action,
                            state.resources[action.resource].data
                        ),
                        isPending: false,
                    },
                },
            };

        default:
            return state;
    }
};

const buildData = (
    action: IApiResultAction,
    currentData?: IBaseData
): IBaseData | undefined => {
    if (!action.data) {
        return currentData;
    }

    return action.data;
};
