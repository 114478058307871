import { Autocomplete, TextField } from '@mui/material';

export interface IFilterableSelectPropsPublic<TData> {
    label: string;
    value?: TData;
    data: TData[];
    onItemSelect: (item?: TData) => void;
    disabled?: boolean;
}

export const Select = <TData,>(props: IFilterableSelectPropsPublic<TData>) => {
    const { label, value, data, onItemSelect, disabled } = props;

    return (
        <Autocomplete
            size='small'
            value={value ?? null}
            options={data}
            disabled={disabled}
            renderInput={params => <TextField {...params} label={label} />}
            onChange={(_, newValue) =>
                onItemSelect(newValue ? newValue : undefined)
            }
            sx={{ my: '10px' }}
            autoHighlight
        />
    );
};
