export type ComponentTypeName =
    | 'Consumer'
    | 'Producer'
    | 'DataResponder'
    | 'DataRequestor';

export const getAvailableComponentTypes = (): ComponentTypeName[] => {
    return ['Consumer', 'Producer', 'DataResponder', 'DataRequestor'];
};

export const getComponentId = (componentName: ComponentTypeName) => {
    switch (componentName) {
        case 'Consumer':
            return 2;
        case 'Producer':
            return 1;
        case 'DataResponder':
            return 3;
        case 'DataRequestor':
            return 4;
    }
};

export const getComponentName = (componentId: number): string => {
    switch (componentId) {
        case 2:
            return 'Consumer';
        case 1:
            return 'Producer';
        case 3:
            return 'DataResponder';
        case 4:
            return 'DataRequestor';
        default:
            return '';
    }
};
