import DeleteIcon from '@mui/icons-material/Delete';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material';
import { deleteEsbComponent } from 'apirequestdefinitions/registration';
import { createErrorSnackbar } from 'helpers/snackbar';
import { useFetch } from 'hooks/useFetch';
import { ComponentRegistration } from 'models/ComponentRegistration';
import { enqueueSnackbar } from 'notistack';
import { useCallback } from 'react';

interface IDeleteComponentDialog {
    isOpen: boolean;
    onCloseDialog: () => void;
    component?: ComponentRegistration;
}

export const DeleteComponentDialog = (props: IDeleteComponentDialog) => {
    const { isOpen, component, onCloseDialog } = props;

    const onDeleteComonentSuccess = useCallback(() => {
        enqueueSnackbar('Komponente erfolgreich gelöscht', {
            variant: 'success',
        });
        onCloseDialog();
    }, [onCloseDialog]);

    const onDeleteComponentFailed = useCallback(
        e => {
            createErrorSnackbar(e, 'Fehler beim Entfernen der Komponenente.');
            onCloseDialog();
        },
        [onCloseDialog]
    );

    const {
        executeApiRequest: deleteComponent,
        isApiRequestPending: isDeletingComponent,
    } = useFetch(
        deleteEsbComponent(component ? component.id : ''),
        onDeleteComonentSuccess,
        onDeleteComponentFailed
    );

    return (
        <Dialog open={isOpen} onClose={onCloseDialog}>
            <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                <DeleteIcon sx={{ mr: 1 }} />
                ESB Komponente entfernen
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {component ? (
                        <>
                            Soll der {component.typeDisplay}{' '}
                            <Typography component='span' color='primary'>
                                {component.name}
                            </Typography>{' '}
                            wirklich vom ESB entfernt werden?
                        </>
                    ) : (
                        <>
                            Soll die Komponente wirklich vom ESB entfernt
                            werden?
                        </>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={isDeletingComponent}
                    onClick={onCloseDialog}
                    color='info'
                >
                    Abbrechen
                </Button>
                <Button
                    color='error'
                    onClick={() => void deleteComponent()}
                    disabled={isDeletingComponent}
                >
                    Entfernen
                </Button>
            </DialogActions>
        </Dialog>
    );
};
