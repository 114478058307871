import { getMessageSourceName } from 'constants/messagesource';
import { IApiRequestDefinition } from 'models/interfaces/api/IApiRequestDefinition';
import { DetailsFilterProperties } from 'models/routing/DetailsFilterProperties';

export const fetchSearch = (
    props: DetailsFilterProperties
): IApiRequestDefinition => {
    const {
        selectedMessageSource: selectedQueue,
        fromDate,
        toDate,
        consumer,
        producer,
        searchableKeyword,
        messageSource: messageType,
        nrOfElements: nrOfElements,
    } = props;
    const fromAsString = fromDate.toISOString();
    const toAsString = toDate.toISOString();
    const queueAsString = getMessageSourceName(selectedQueue);

    let url = `/esb/api/app/search/${queueAsString}?from=${fromAsString}&to=${toAsString}`;
    if (consumer) {
        url = url + `&consumer=${consumer}`;
    }
    if (producer) {
        url = url + `&producer=${producer}`;
    }
    if (messageType) {
        url = url + `&messageType=${messageType}`;
    }
    if (searchableKeyword) {
        url = url + `&searchableKeyword=${searchableKeyword}`;
    }
    url = url + `&nrOfElements=${nrOfElements > 0 ? nrOfElements : 100}`;
    return {
        method: 'get',
        url: url,
        canExecute: true
    };
};
