import { IPublicClientApplication } from '@azure/msal-browser';

interface IRolesContainer {
    roles: string[];
}

const isRolesContainer = (value: object): value is IRolesContainer =>
    Object.prototype.hasOwnProperty.call(value, 'roles');

export const hasUserNeededRoles = (
    neededRoles: string[],
    appInstance: IPublicClientApplication
) => {
    if (neededRoles.length === 0) {
        return true;
    }
    const currentAccount = appInstance.getActiveAccount();

    if (
        currentAccount?.idTokenClaims !== undefined &&
        isRolesContainer(currentAccount.idTokenClaims)
    ) {
        const roles = currentAccount.idTokenClaims.roles;
        return neededRoles.every(role => roles.includes(role));
    }

    return false;
};
