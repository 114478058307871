export enum MessageSource {
    Archived,
    DeadLettered,
    Blacklisted,
}

export const getMessageSourceName = (q: MessageSource) => {
    switch (q) {
        case MessageSource.Archived:
            return 'Processed';
        case MessageSource.DeadLettered:
            return 'Deadlettered';
        case MessageSource.Blacklisted:
            return 'Blacklisted';
        default:
            return '';
    }
};
