import { getDateDisplay } from 'helpers/model';
import { cloneDeep } from 'lodash';
import { getComponentName } from 'models/ComponentTypes';
import { IConvertable } from 'models/interfaces/IConvertable';

export class ComponentRegistration
    implements IConvertable<ComponentRegistration>
{
    id = '';
    type = -1;
    typeDisplay = '';
    name = '';
    consumedMessageTypes: string[] = [];
    consumedMessageTypesDisplay = '';
    producedMessageTypes: string[] = [];
    producedMessageTypesDisplay = '';
    heartbeatInterval = '';
    lastHeartbeat = '';

    convertToModel = () => {
        const clone = cloneDeep(this);
        clone.consumedMessageTypesDisplay = this.createMessageTypeDisplay(
            this.consumedMessageTypes
        );
        clone.producedMessageTypesDisplay = this.createMessageTypeDisplay(
            this.producedMessageTypes
        );
        clone.lastHeartbeat = getDateDisplay(clone.lastHeartbeat);
        clone.typeDisplay = getComponentName(clone.type);
        return clone;
    };

    private createMessageTypeDisplay = (messageTypes: string[]) => {
        return messageTypes.join(', ');
    };
}
