import { Box, Typography } from '@mui/material';

interface IProps {
    title: string;
    icon: React.ReactNode;
}

export const NonIdealState = ({ title, icon }: IProps) => {
    return (
        <Box
            sx={{
                display: 'grid',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    '& > svg': { fontSize: '80px' },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    my: 2,
                }}
            >
                {icon}
            </Box>
            <Typography variant='h6'>{title}</Typography>
        </Box>
    );
};
