import { Box } from '@mui/material';
import { ComplexPropertyValue } from 'components/widgets/JsonObjectRenderer/ComplexPropertyValue';
import { getDateDisplay, isValidIso8601Date } from 'helpers/model';

interface IPropertyViewProps {
    value: object | string | null;
    linkAction?: () => void;
}

export const PropertyValue = (props: IPropertyViewProps) => {
    const { value, linkAction } = props;

    if (typeof value === 'object' && value) {
        return <ComplexPropertyValue value={value} />;
    }

    let displayValue = value;
    if (typeof value === 'string' && value.length > 4 && isValidIso8601Date(value)) {
        displayValue = getDateDisplay(value);
    }

    if (typeof value === 'boolean') {
        displayValue = (value as boolean) ? 'true' : 'false';
    }

    if (value === null) {
        displayValue = 'null';
    }

    return (
        <Box component='span'>
            {linkAction ? (
                <a onClick={linkAction}>{displayValue}</a>
            ) : (
                displayValue
            )}
        </Box>
    );
};
