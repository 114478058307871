import { queryStringParseOptions } from 'constants/queryStringParseOptions';
import { stringify } from 'query-string';
import { useCallback } from 'react';
import { useHistory } from 'react-router';

export const useRouting = () => {
    const history = useHistory();

    const push = useCallback(
        (path: string, search?: object) => {
            history.push({
                pathname: path,
                search: search
                    ? stringify(search, queryStringParseOptions)
                    : undefined,
            });
        },
        [history]
    );

    return { navigateTo: push };
};
