import { Box } from '@mui/material';
import { PropertyValue } from 'components/widgets/JsonObjectRenderer/PropertyValue';

interface IProperty {
    name: string;
    value: object | string | null;
    linkAction?: () => void;
}

export const Property = (props: IProperty) => {
    const { name, value, linkAction } = props;

    return (
        <Box mb={1}>
            <Box component='span' color='info.light' mr={1}>
                {name}:
            </Box>
            <PropertyValue value={value} linkAction={linkAction} />
        </Box>
    );
};
