import { Box, CircularProgress, TextField } from '@mui/material';
import { fetchContractVersion } from 'apirequestdefinitions/documentation';
import { convertProperties } from 'helpers/jsonConverter';
import { useFetch } from 'hooks/useFetch';
import { ContractOverview } from 'models/ContractOverview';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';

interface IJsonFieldProps {
    contractType?: string;
    version?: string;
    body: string;
    setBody: (value: string) => void;
    isBodyValid: boolean;
}

export const JsonField = (props: IJsonFieldProps) => {
    const { contractType, version, setBody, body, isBodyValid } = props;

    const onLoadSuccess = useCallback(
        (result: ContractOverview) =>
            setBody(
                JSON.stringify(convertProperties(result.properties), null, 4)
            ),
        [setBody]
    );

    const onLoadFailed = useCallback(
        () => enqueueSnackbar('Kann Messagebody nicht anzeigen'),
        []
    );

    const { executeApiRequest: loadDetails, isApiRequestPending } = useFetch(
        fetchContractVersion(contractType ?? '', version ?? ''),
        onLoadSuccess,
        onLoadFailed
    );

    useEffect(() => {
        void loadDetails();
    }, [loadDetails]);

    if (isApiRequestPending) {
        return (
            <Box
                sx={{
                    overflow: 'auto',
                    p: '0.5em 1em',
                    display: 'grid',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <CircularProgress color='primary' />
            </Box>
        );
    }

    return (
        <>
            <TextField
                sx={{
                    my: '10px',
                    width: '100%',
                }}
                label='Messagebody'
                name='messageBody'
                size='small'
                variant='outlined'
                spellCheck={false}
                multiline
                value={body}
                onChange={event => {
                    setBody(event.currentTarget.value);
                }}
                error={!!body && !isBodyValid}
            />
        </>
    );
};
