import CloseIcon from '@mui/icons-material/Close';
import {
    AppBar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Paper,
    Toolbar,
    Typography,
} from '@mui/material';
import { fetchContractVersions } from 'apirequestdefinitions/documentation';
import { sendManualMessage } from 'apirequestdefinitions/messages';
import { JsonField } from 'components/pages/SendMessage/JsonField';
import { Select } from 'components/widgets/Select';
import { validateJson } from 'helpers/jsonConverter';
import { useFetch } from 'hooks/useFetch';
import { IOverviewVersion } from 'models/Documentation/IOverviewVersion';
import { IAppState } from 'models/interfaces/api/IAppState';
import { IBaseData } from 'models/interfaces/IBaseData';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface ISendMessageDialogProps {
    isDialogOpen: boolean;
    closeDialog: () => void;
}

export const SendMessage = (props: ISendMessageDialogProps) => {
    const { isDialogOpen, closeDialog } = props;
    const messageKindOptions = ['BroadcastManual', 'Point2PointManual'];
    const messageActionOptions = ['Upsert', 'Delete'];

    const [messageAction, setMessageAction] = useState<string>();
    const [messageKind, setMessageKind] = useState<string>();
    const [messageType, setMessageType] = useState<string>();
    const [producer, setProducer] = useState<string>();
    const [consumer, setConsumer] = useState<string>();
    const [version, setVersion] = useState<string>();
    const [body, setBody] = useState('');

    const reset = () => {
        setMessageAction(undefined);
        setMessageKind(undefined);
        setMessageType(undefined);
        setProducer(undefined);
        setConsumer(undefined);
        setBody('');
    };

    const baseData = useSelector<IAppState, IBaseData | undefined>(
        state => state.api.resources.baseData.data
    );

    const onLoadSuccess = useCallback((result: IOverviewVersion[]) => {
        setVersion(
            result.sort((a, b) => b.number.localeCompare(a.number))[0].number
        );
    }, []);

    const onLoadFailed = useCallback(() => setVersion(''), []);

    const { executeApiRequest: loadVersion } = useFetch(
        fetchContractVersions(),
        onLoadSuccess,
        onLoadFailed
    );

    const onSendSuccess = useCallback(() => {
        enqueueSnackbar('Message erfolgreich versendet.', {
            variant: 'success',
        });
        closeDialog();
    }, [closeDialog]);

    const onSendFailed = useCallback(
        () =>
            enqueueSnackbar('Message konnte nicht versendet werden.', {
                variant: 'error',
            }),
        []
    );

    const onSendFinished = useCallback(isSuccess => {
        if (isSuccess) {
            reset();
        }
    }, []);

    const { executeApiRequest: sendMessage, isApiRequestPending: isSending } =
        useFetch(
            sendManualMessage(
                messageAction ?? '',
                messageType ?? '',
                messageKind ?? '',
                producer ?? '',
                body,
                consumer
            ),
            onSendSuccess,
            onSendFailed,
            onSendFinished
        );

    const isBodyValid = validateJson(body);

    const isSendEnabled = (): boolean => {
        return (
            !isSending &&
            messageAction !== undefined &&
            messageType !== undefined &&
            producer !== undefined &&
            (messageKind === 'BroadcastManual' ||
                (messageKind === 'Point2PointManual' &&
                    consumer !== undefined)) &&
            isBodyValid
        );
    };

    const handleMessageKindSelect = (item: string | undefined) => {
        setMessageKind(item);
        if (item === 'BroadcastManual') {
            setConsumer(undefined);
        }
    };

    useEffect(() => {
        if (!version) {
            void loadVersion();
        }
    }, [messageType, version, loadVersion]);

    useEffect(() => {
        reset();
    }, [isDialogOpen]);

    return (
        <Dialog
            open={isDialogOpen}
            onClose={closeDialog}
            maxWidth='sm'
            fullWidth
        >
            <AppBar sx={{ position: 'relative' }} enableColorOnDark>
                <Toolbar>
                    <IconButton
                        edge='end'
                        color='inherit'
                        onClick={closeDialog}
                        aria-label='close'
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography
                        sx={{ ml: 2, flex: 1 }}
                        variant='h6'
                        component='div'
                    >
                        Neue Message versenden
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent
                sx={{
                    backgroundColor: 'background.default',
                    paddingLeft: '18px',
                    paddingRight: '18px',
                }}
            >
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateRows: 'auto auto',
                        gap: '20px',
                        width: '100%',
                    }}
                >
                    <Paper
                        elevation={2}
                        sx={{
                            height: 'fit-content',
                            display: 'grid',
                            gridTemplateColumns: {
                                xs: 'repeat(1, auto)',
                                lg: 'repeat(2, auto)',
                            },
                            padding: '10px 18px 10px 18px',
                            minWidth: '100%',
                            width: { xs: '100%', lg: '100%' },
                            columnGap: '18px',
                        }}
                    >
                        <Select
                            label='Message Typ'
                            value={messageType}
                            data={
                                baseData?.messageTypes.filter(
                                    i =>
                                        !i.endsWith('Response') &&
                                        !i.endsWith('Request')
                                ) ?? []
                            }
                            onItemSelect={item => {
                                if (item) {
                                    setMessageType(item);
                                } else {
                                    setBody('');
                                }
                            }}
                        />
                        <Select
                            label='Action'
                            value={messageAction}
                            data={messageActionOptions}
                            onItemSelect={item => setMessageAction(item)}
                        />
                        <Select
                            label='Messagekind'
                            value={messageKind}
                            data={messageKindOptions}
                            onItemSelect={item => handleMessageKindSelect(item)}
                        />
                        <Select
                            label='Producer'
                            value={producer}
                            data={baseData?.producers ?? []}
                            onItemSelect={item => setProducer(item)}
                        />
                        <Select
                            label='Consumer'
                            value={consumer}
                            data={baseData?.consumers ?? []}
                            onItemSelect={item => setConsumer(item)}
                            disabled={messageKind === 'BroadcastManual'}
                        />
                    </Paper>
                    <Paper
                        elevation={2}
                        sx={{
                            height: 'fit-content',
                            padding: '10px 18px 10px 18px',
                            width: { xs: '100%', lg: '100%' },
                        }}
                    >
                        <JsonField
                            version={version}
                            contractType={messageType}
                            setBody={setBody}
                            body={body}
                            isBodyValid={isBodyValid}
                        />
                    </Paper>
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    justifyContent: 'flex-start',
                    columnGap: '10px',
                    ml: '10px',
                }}
            >
                <Button
                    color='success'
                    variant='contained'
                    size='small'
                    onClick={() => {
                        void sendMessage();
                    }}
                    disabled={!isSendEnabled()}
                >
                    Message senden
                </Button>
                <Button
                    color='error'
                    variant='contained'
                    size='small'
                    disabled={isSending}
                    onClick={reset}
                >
                    Zurücksetzen
                </Button>
            </DialogActions>
        </Dialog>
    );
};
