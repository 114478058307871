import { IBaseData } from 'models/interfaces/IBaseData';
import { IApiResources } from 'models/interfaces/api/IApiResources';
import {
    API_RESULT,
    IApiResultAction,
} from 'models/interfaces/api/IApiResultAction';

export const saveApiResult = (
    resource: keyof IApiResources,
    data: IBaseData | undefined,
    appendData?: boolean
): IApiResultAction => ({
    type: API_RESULT,
    resource,
    data,
    appendData,
});
