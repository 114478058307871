import { Box } from '@mui/material';
import { Property } from 'components/widgets/JsonObjectRenderer/Property';
import { PropertyValue } from 'components/widgets/JsonObjectRenderer/PropertyValue';

interface IComplexPropertyValueProps {
    value: object;
}

export const ComplexPropertyValue = ({ value }: IComplexPropertyValueProps) => {
    if (Array.isArray(value)) {
        return (
            <>
                {value.map((v, i) => {
                    return (
                        <Box
                            component='span'
                            key={i}
                            border={1}
                            borderRadius={1}
                            mr={1}
                            px={0.5}
                            py={0.2}
                            sx={{ backgroundColor: 'background.paper' }}
                        >
                            <PropertyValue value={v as object | string} />
                        </Box>
                    );
                })}
            </>
        );
    }

    return (
        <Box
            sx={{
                borderRadius: '3px',
                px: '8px',
                mt: '5px',
                borderLeft: 3,
                borderColor: 'success.main',
            }}
        >
            {Object.entries(value).map(([key, val]) => (
                <Property key={key} name={key} value={val as object | string} />
            ))}
        </Box>
    );
};
