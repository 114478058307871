import { IBaseData } from 'models/interfaces/IBaseData';
import { IApiResources } from 'models/interfaces/api/IApiResources';

export const API_RESULT = 'API_RESULT';

export interface IApiResultAction {
    type: typeof API_RESULT;
    resource: keyof IApiResources;
    data: IBaseData | undefined;
    appendData?: boolean;
    errors?: unknown;
}
