import { JsonObjectRenderer } from 'components/widgets/JsonObjectRenderer';
import { useRouting } from 'hooks/useRouting';
import { DetailEsbMessage } from 'models/DetailEsbMessage';
import { DocumentationQueryParams } from 'models/routing/DocumentationQueryParams';

interface IEsbMessageRendererProps {
    message: DetailEsbMessage;
}

export const EsbMessageRenderer = ({ message }: IEsbMessageRendererProps) => {
    const { navigateTo } = useRouting();

    const linkActions: Record<string, () => void> = {
        messageType: () => {
            const queryParams = new DocumentationQueryParams();
            queryParams.type = message.messageType;
            queryParams.version = message.version;
            navigateTo('doc', queryParams);
        },
    };

    return <JsonObjectRenderer json={message} linkActions={linkActions} />;
};
