import moment from 'moment';
import { parse, ParseOptions, stringify, StringifyOptions } from 'query-string';

export const stringifyQuery = <T extends object>(
    object?: T,
    options?: StringifyOptions | undefined
): string => {
    if (object === undefined) {
        return '';
    }
    const toSerialize: Record<string, unknown> = {};
    Object.entries(object).forEach(([key, value]) => {
        if (moment.isMoment(value) || value instanceof Date) {
            toSerialize[key] = value.toISOString();
        } else if (typeof value === 'string' && value.length === 0) {
            // do nothing
        } else {
            toSerialize[key] = value;
        }
    });
    return stringify(toSerialize, options);
};

export const parseQuery = (query: string, options?: ParseOptions) => {
    const parsed = parse(query, options);
    const result: Record<string, unknown> = {};
    Object.entries(parsed).forEach(([key, value]) => {
        let valueToWrite: unknown = value;
        if (typeof value === 'string') {
            const valueAsDate = moment(value, moment.ISO_8601, true);
            if (valueAsDate.isValid()) {
                valueToWrite = valueAsDate;
            }
        }
        result[key] = valueToWrite;
    });
    return result;
};
