import { MessageSource, getMessageSourceName } from 'constants/messagesource';
import { IApiRequestDefinition } from 'models/interfaces/api/IApiRequestDefinition';
import { OverviewEsbMessage } from 'models/OverviewEsbMessage';

export const sendMessage = (
    messageSource: MessageSource,
    message?: OverviewEsbMessage
): IApiRequestDefinition => ({
    method: 'get',
    url: `/esb/api/app/send/${getMessageSourceName(messageSource)}/${message?.messageType ?? ''
        }/${message?.id ?? ''}`,
    canExecute: getMessageSourceName(messageSource).length > 0 && message !== undefined && message.messageType.length > 0 && message.id.length > 0
});

export const sendManualMessage = (
    action: string,
    messageType: string,
    messageKind: string,
    producerName: string,
    body: string,
    consumerName?: string
): IApiRequestDefinition => ({
    method: 'post',
    url: `/esb/api/app/sendmanual`,
    canExecute: true,
    body: {
        action: action,
        messageType: messageType,
        messageKind: messageKind,
        producerName: producerName,
        consumerName: consumerName,
        body: body
    }
});

export const deleteMessage = (
    messageSource: MessageSource,
    message?: OverviewEsbMessage
): IApiRequestDefinition => ({
    method: 'delete',
    url: `/esb/api/app/delete/${getMessageSourceName(messageSource)}/${message?.messageType ?? ''
        }/${message?.id ?? ''}`,
    canExecute: getMessageSourceName(messageSource).length > 0 && message !== undefined && message.messageType.length > 0 && message.id.length > 0
});

export const fetchMessageDetail = (
    partitionKey: string,
    rowKey: string
): IApiRequestDefinition => ({
    method: 'get',
    url: `/esb/api/app/detail/${partitionKey}/${rowKey}`,
    canExecute: true
});
