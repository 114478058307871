import { Box } from '@mui/material';
import { Property } from 'components/widgets/JsonObjectRenderer/Property';

interface IJsonObjectRendererProps {
    json?: object | string;
    linkActions?: Record<string, () => void>;
}

export const JsonObjectRenderer = ({
    json,
    linkActions,
}: IJsonObjectRendererProps) => {
    return json ? (
        <Box mt={2} ml={2} sx={{ fontSize: '14px' }}>
            {Object.entries(json).map(([key, value]) => (
                <Property
                    key={key}
                    name={key}
                    value={value as object | string | null}
                    linkAction={linkActions ? linkActions[key] : undefined}
                />
            ))}
        </Box>
    ) : (
        <></>
    );
};
