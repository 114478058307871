import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import { registerEsbComponent } from 'apirequestdefinitions/registration';
import { Select } from 'components/widgets/Select';
import { useFetch } from 'hooks/useFetch';
import {
    ComponentTypeName,
    getAvailableComponentTypes,
    getComponentId,
} from 'models/ComponentTypes';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useState } from 'react';

interface IAddComponentDialog {
    isOpen: boolean;
    onCloseDialog: () => void;
}

export const AddComponentDialog = (props: IAddComponentDialog) => {
    const { isOpen, onCloseDialog } = props;
    const [componentName, setComponentName] = useState('');
    const [selectedTypeName, setSelectedTypeName] =
        useState<ComponentTypeName>();

    const resetDialog = () => {
        setComponentName('');
        setSelectedTypeName(undefined);
    };

    const closeAndResetDialog = useCallback(() => {
        onCloseDialog();
        resetDialog();
    }, [onCloseDialog]);

    const onRegisterComponentFailed = useCallback(
        (r: unknown) => {
            let message = 'Fehler beim Erstellen der Komponente';
            if (r === 409 && selectedTypeName) {
                message = `Es existiert bereits ein ${selectedTypeName} mit dem Namen ${componentName}. Bitte Namen anpassen.`;
            }
            enqueueSnackbar(message, { variant: 'error' });
        },
        [componentName, selectedTypeName]
    );

    const onRegisterComponentSuccess = useCallback(
        (r: string) => {
            enqueueSnackbar(`Komponente mit Id '${r}' erstellt`, {
                variant: 'success',
            });
            closeAndResetDialog();
        },
        [closeAndResetDialog]
    );

    const {
        executeApiRequest: createComponent,
        isApiRequestPending: isSending,
    } = useFetch(
        registerEsbComponent(
            componentName,
            selectedTypeName ? getComponentId(selectedTypeName) : -1
        ),
        onRegisterComponentSuccess,
        onRegisterComponentFailed
    );

    return (
        <Dialog open={isOpen} onClose={closeAndResetDialog} fullWidth>
            <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                <AddCircleIcon sx={{ mr: 1 }} />
                ESB Komponente erstellen
            </DialogTitle>
            <DialogContent>
                <TextField
                    label='Name'
                    value={componentName}
                    onChange={event =>
                        setComponentName(event.currentTarget.value)
                    }
                    size='small'
                    fullWidth
                    sx={{ my: 1 }}
                />
                <Select
                    label='Typ'
                    data={getAvailableComponentTypes()}
                    value={selectedTypeName}
                    onItemSelect={item => setSelectedTypeName(item)}
                />
            </DialogContent>
            <DialogActions>
                <Button color='info' onClick={closeAndResetDialog}>
                    Abbrechen
                </Button>
                <Button
                    color='primary'
                    onClick={() => void createComponent()}
                    disabled={
                        !(componentName.length > 3 && selectedTypeName) ||
                        isSending
                    }
                >
                    Erstellen
                </Button>
            </DialogActions>
        </Dialog>
    );
};
