/* eslint-disable no-console */
import { IPublicClientApplication } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AccountCircle } from '@mui/icons-material';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import {
    AppBar,
    Button,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItemButton,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { ThemeSwitch, useTheme } from '@ost/mui';
import { AppRoles, loginRequest } from 'authConfig';
import { RoutePath } from 'constants/routing';
import { hasUserNeededRoles } from 'helpers/authRoleChecker';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';

const pages = [
    { name: 'Details', url: RoutePath.HOME, rolesNeeded: [] },
    {
        name: 'Dokumentation',
        url: RoutePath.DOCUMENTATION,
        rolesNeeded: [],
    },
    {
        name: 'Registrierung',
        url: RoutePath.REGISTRATION,
        rolesNeeded: [AppRoles.Admin],
    },
];

export const NavBar = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(prevState => !prevState);
    };
    const container = window.document.body;
    const { instance } = useMsal();
    const { theme, updateTheme } = useTheme();
    const isAuthenticated = useIsAuthenticated();
    const history = useHistory();
    const location = useLocation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = (instance: IPublicClientApplication) => {
        instance.logoutPopup().catch(e => console.error(e));
        handleMenuClose();
    };

    const handleLogin = (instance: IPublicClientApplication) => {
        instance.loginPopup(loginRequest).catch(e => console.error(e));
    };

    return (
        <AppBar position='static' enableColorOnDark component='nav'>
            <Toolbar>
                <Typography
                    variant='h6'
                    noWrap
                    component='a'
                    href='/'
                    align='left'
                    sx={{
                        mr: 2,
                        display: { xs: 'none', md: 'flex' },
                        fontWeight: 700,
                        color: 'inherit',
                        '&:hover': {
                            color: 'primary.dark',
                        },
                        textDecoration: 'none',
                    }}
                >
                    ESB Cockpit
                </Typography>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: { xs: 'flex', md: 'none' },
                    }}
                >
                    <IconButton
                        color='inherit'
                        aria-label='open drawer'
                        edge='start'
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Box>
                <Box component='nav'>
                    <Drawer
                        container={container}
                        variant='temporary'
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                            '& .MuiDrawer-paper': {
                                boxSizing: 'border-box',
                                width: 240,
                            },
                        }}
                    >
                        <Box
                            onClick={handleDrawerToggle}
                            sx={{
                                textAlign: 'center',
                            }}
                        >
                            <Typography variant='h6' sx={{ my: 2 }}>
                                ESB Cockpit
                            </Typography>
                            <Divider />
                            <List>
                                {pages.map(
                                    page =>
                                        isAuthenticated &&
                                        hasUserNeededRoles(
                                            page.rolesNeeded,
                                            instance
                                        ) && (
                                            <ListItemButton
                                                key={page.name}
                                                sx={{
                                                    textAlign: 'left',
                                                }}
                                                selected={location.pathname.endsWith(
                                                    page.url
                                                )}
                                                onClick={() =>
                                                    history.push(page.url)
                                                }
                                            >
                                                {page.name}
                                            </ListItemButton>
                                        )
                                )}
                            </List>
                        </Box>
                    </Drawer>
                </Box>
                <Typography
                    variant='h5'
                    noWrap
                    component='a'
                    href='/'
                    sx={{
                        mr: 2,
                        display: { xs: 'flex', md: 'none' },
                        flexGrow: 1,
                        fontWeight: 700,
                        color: 'inherit',
                        '&:hover': {
                            color: 'primary.dark',
                        },
                    }}
                >
                    ESB Cockpit
                </Typography>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: { xs: 'none', md: 'flex' },
                        height: 64,
                    }}
                >
                    {pages.map(
                        page =>
                            isAuthenticated &&
                            hasUserNeededRoles(page.rolesNeeded, instance) && (
                                <Button
                                    key={page.name}
                                    size='medium'
                                    sx={{
                                        my: 2,
                                        display: 'block',
                                        '&:hover': {
                                            backgroundColor: 'primary.dark',
                                        },
                                        fontWeight: location.pathname.endsWith(
                                            page.url
                                        )
                                            ? 600
                                            : 500,
                                        color: location.pathname.endsWith(
                                            page.url
                                        )
                                            ? 'white'
                                            : 'primary.100',
                                    }}
                                    onClick={() => history.push(page.url)}
                                >
                                    {page.name}
                                </Button>
                            )
                    )}
                </Box>
                <Box sx={{ flexGrow: 0 }}>
                    {isAuthenticated ? (
                        <>
                            <IconButton
                                size='large'
                                aria-label='account of current user'
                                aria-controls='menu-appbar'
                                aria-haspopup='true'
                                onClick={handleMenuOpen}
                                color='inherit'
                            >
                                <AccountCircle fontSize='large' />
                            </IconButton>
                            <Menu
                                id='menu-appbar'
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem
                                    onClick={() => handleLogout(instance)}
                                >
                                    <LogoutIcon sx={{ mr: 2 }} />
                                    LOGOUT
                                </MenuItem>
                                <MenuItem
                                    onClick={() =>
                                        theme === 'dark'
                                            ? updateTheme('light')
                                            : updateTheme('dark')
                                    }
                                >
                                    THEME
                                    <ThemeSwitch checked={theme === 'dark'} />
                                </MenuItem>
                            </Menu>
                        </>
                    ) : (
                        <Tooltip title='Sign In'>
                            <Button
                                sx={{
                                    color: 'inherit',
                                    ml: 2,
                                }}
                                size='small'
                                startIcon={<LoginIcon />}
                                onClick={() => handleLogin(instance)}
                            >
                                Login
                            </Button>
                        </Tooltip>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
};
