import { IApiRequestDefinition } from 'models/interfaces/api/IApiRequestDefinition';

export const registerEsbComponent = (
    componentName: string,
    componentType: number
): IApiRequestDefinition => ({
    method: 'post',
    url: `/esb/api/app/register`,
    canExecute: true,
    body: {
        name: componentName,
        type: componentType
    }
});

export const fetchEsbComponents = (): IApiRequestDefinition => ({
    method: 'get',
    url: `/esb/api/app/registrations`,
    canExecute: true
});

export const deleteEsbComponent = (id: string): IApiRequestDefinition => ({
    method: 'delete',
    url: `/esb/api/app/unregister/${id}`,
    canExecute: true
});
