import { queryStringParseOptions } from 'constants/queryStringParseOptions';
import { parseQuery, stringifyQuery } from 'helpers/query';
import { usePrevious } from 'hooks/usePrevious';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

export const useRoutingQueryStringFilter = <T extends object>(
    modelType: new () => T
) => {
    const location = useLocation();
    const history = useHistory();

    const parsed = parseQuery(location.search, queryStringParseOptions);
    const initial = Object.assign(new modelType(), parsed);
    const [queryFilter, setQueryFilter] = useState<T>(initial);
    const previous = usePrevious(queryFilter);

    useEffect(() => {
        const queryString = stringifyQuery(queryFilter);
        if (queryString !== stringifyQuery(previous)) {
            history.replace({
                search: stringifyQuery(queryFilter),
            });
        }
    }, [history, queryFilter, previous]);

    return { queryFilter, setQueryFilter };
};
