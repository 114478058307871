export const formatHugeNumber = (s: string) => {
    try {
        const n: number = +s;

        if (n >= 1_000_000) {
            return `${(n / 1_000_000).toFixed(1)} M`;
        }
        if (n >= 10_000) {
            return `${(n / 1_000).toFixed(1)} k`;
        }
        if (n >= 1_000) {
            return `${n.toLocaleString('de-CH')}`;
        }
        return n;
    } catch {
        return s;
    }
};
