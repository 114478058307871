/* eslint-disable no-console */
import { IPublicClientApplication } from '@azure/msal-browser';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
} from '@azure/msal-react';
import LoginIcon from '@mui/icons-material/Login';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FixedPageContent, SnackbarProvider, ThemeManager } from '@ost/mui';
import { AppRoles, loginRequest } from 'authConfig';
import { RouteGuard } from 'components/RouteGuard';
import { Startup } from 'components/Startup';
import { Details } from 'components/pages/Details/index';
import { Documentation } from 'components/pages/Documentation/index';
import { Registration } from 'components/pages/Registration/index';
import { NavBar } from 'components/widgets/NavBar';
import { RoutePath } from 'constants/routing';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import { rootReducer } from 'reducers';
import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

const store = createStore(rootReducer, composeWithDevTools());

export const App = () => {
    const { instance } = useMsal();
    const handleLogin = (instance: IPublicClientApplication) => {
        instance.loginPopup(loginRequest).catch(e => console.error(e));
    };

    return (
        <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <ThemeManager>
                    <BrowserRouter>
                        <SnackbarProvider
                            maxSnack={5}
                            anchorOrigin={{
                                horizontal: 'right',
                                vertical: 'top',
                            }}
                        >
                            <NavBar />
                            <AuthenticatedTemplate>
                                <Startup>
                                    <Switch>
                                        <RouteGuard
                                            exact
                                            path={RoutePath.REGISTRATION}
                                            roles={[AppRoles.Admin]}
                                            component={Registration}
                                        />
                                        <RouteGuard
                                            exact
                                            path={RoutePath.HOME}
                                            roles={[]}
                                            component={Details}
                                        />
                                        <RouteGuard
                                            exact
                                            path={RoutePath.DOCUMENTATION}
                                            roles={[]}
                                            component={Documentation}
                                        />
                                    </Switch>
                                </Startup>
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate>
                                <FixedPageContent>
                                    <Box sx={{ ml: 2, mt: 2 }}>
                                        <Typography variant='body1' mb={1}>
                                            Please Login to access the site
                                        </Typography>
                                        <Button
                                            variant='contained'
                                            startIcon={<LoginIcon />}
                                            onClick={() =>
                                                handleLogin(instance)
                                            }
                                        >
                                            Login
                                        </Button>
                                    </Box>
                                </FixedPageContent>
                            </UnauthenticatedTemplate>
                        </SnackbarProvider>
                    </BrowserRouter>
                </ThemeManager>
            </LocalizationProvider>
        </Provider>
    );
};
