import { Box } from '@mui/material';

interface IContentProps {
    children?: React.ReactNode;
}

export const Content: React.FunctionComponent = ({
    children,
}: IContentProps) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flex: 1,
                p: '20px',
                overflowY: 'auto',
                flexDirection: { xs: 'column', lg: 'row' },
            }}
        >
            {children}
        </Box>
    );
};
