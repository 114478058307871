import { forEach } from 'lodash';
import { enqueueSnackbar } from 'notistack';

interface IFluentValidationError {
    errors: Record<string, string[]>;
}

export const createErrorSnackbar = (
    error: unknown,
    defaultFallbackMessage: string
) => {
    const errorConcat = new Set<string>();
    if (Object.prototype.hasOwnProperty.call(error, 'errors')) {
        const errorObj = error as IFluentValidationError;
        forEach(errorObj.errors, value => {
            value.forEach((e: string) => errorConcat.add(e));
        });
    } else {
        errorConcat.add(defaultFallbackMessage);
    }
    enqueueSnackbar(Array.from(errorConcat).join(' / '), { variant: 'error' });
};
