import { isConvertableToModel } from 'models/interfaces/IConvertable';
import moment from 'moment';

export const convertModel = <T extends object>(
    modelType: new () => T,
    model: object
): T => {
    const newModel = new modelType();
    Object.assign(newModel, model);
    if (isConvertableToModel<T>(newModel)) {
        return newModel.convertToModel();
    }
    return newModel;
};

export const convertModelArray = <T extends object>(
    modelType: new () => T,
    models?: object[]
): T[] => {
    return models?.map(model => convertModel(modelType, model)) ?? [];
};

export const getDateDisplay = (value?: string) => {
    return value ? moment(value).format('DD.MM.YYYY HH:mm:ss.SSS') : '';
};

export const isValidIso8601Date = (value?: string) =>
    moment(value, moment.ISO_8601, true).isValid();
