import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

export const useStoredObject = <TData extends object>(obj: TData) => {
    const [data, setData] = useState(obj);

    useEffect(() => {
        if (!isEqual(data, obj)) {
            setData(obj);
        }
    }, [data, obj]);

    return data;
};
